import React from 'react';
import { Link } from '../Link';
import { ReactSVG } from 'react-svg';

import rg_21 from '../../assets/icons/common/RG_21.svg';
import {
  Container,
  ResponsiveText,
  ResponsiveTextSmall,
} from './ResponsibleGamingStyles';

export const ResponsibleGamingSportsbook = () => (
  <Container>
    <ReactSVG src={rg_21} />
    <ResponsiveText>
      Gambling Problem? Call 1-800-GAMBLER or visit{' '}
      <Link
        href="https://www.rg-help.com"
        target="_blank"
        children={<>RG-help.com</>}
      />
      . <br />
      Call 1-888-789-7777 or visit{' '}
      <Link
        href="https://ccpg.org/chat/"
        target="_blank"
        children={<>ccpg.org/chat</>}
      />{' '}
      (CT). <br />
      Visit{' '}
      <Link
        href="https://www.mdgamblinghelp.org"
        target="_blank"
        children={<>www.mdgamblinghelp.org</>}
      />{' '}
      (MD). <br />
      Call 1-877-8HOPE-NY or text HOPENY (467369) (NY).
      <br />
    </ResponsiveText>
    <ResponsiveTextSmall>
      21+ (18+ D.C. & PR) and present in AZ, CO, CT, D.C., IA, IL, IN, KS (in
      affiliation with Kansas Star Casino), KY, LA (permitted parishes only),
      MD, MI, NC, NJ, NY, OH, PA, TN, VA, VT, WV, or WY. If playing causes you
      financial, family and occupational problems, call the ASSMCA PAS line at
      1-800-981-0023 (PR).
    </ResponsiveTextSmall>
  </Container>
);
