import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  max-width: 550px;
  ${({ theme }) => `
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                width: 250px
            }`
        }
    `}
`;

export const Text = styled.div`
  padding-top: 8px;
  font-size: 14px;
  text-align: center;
  ${({ theme }) => `
        color: ${theme.colors.headerText};
        ${
          theme.mediaQueries.extraSmall.maxWidth &&
          `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                font-size: 13px;
            }`
        }
    `}
`;

export const Line = styled.div`
  white-space: nowrap;
`;

export const ResponsiveText = styled.div`
padding-top: 8px;
text-align: center;
font-size: clamp(1.00rem, calc(0.92rem + 0.39vw), 1.25rem);

`;

export const ResponsiveTextSmall = styled.div`
padding-top: 8px;
text-align: center;
font-size: clamp(0.83rem, calc(0.82rem + 0.08vw), 0.88rem);
`;